i {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0px;
    padding: 0px;
}
i svg {
    fill:#000000;
    max-width:100%;
}
i.d-block svg{
vertical-align: baseline;
}
i.light svg {
    fill:#FFFFFF;
}
i.alt svg {
    fill:rgb(255, 71, 19);
}
i.text {
    vertical-align:text-bottom;
}
i.xxs {
    height:0.75rem;
    width:0.75rem;
}
i.xs {
    height:1rem;
    width:1rem;
}
i.sm {
    height:1.5rem;
    width:1.5rem;
}
i.md {
    width:2rem;
    height:2rem;
}
i.lg {
    width:3rem;
    height:3rem;
}
i.xl {
    width:4rem;
    height:4rem;
}
