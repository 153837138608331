/* colors */
:root {
    --theme: #333333;
    --theme-overlay: rgba(51, 51, 51,0.8);
    --theme-alt:rgb(255, 71, 19);
    --theme-alt-overlay: rgba(255, 71, 19,0.8);
    --dark-overlay: rgba(0,0,0,0.7);
    --mid-overlay: rgba(0,0,0,0.4);

    --theme-sidebar: #f8f9fa;
    --light-text: #FFFFFF;
    --dark-text: #000000;
    --bg-grey: #f5f5f5;
    --border: #e0e0e0;
  }
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');

@font-face {
  font-family: 'Din';
  src: url('../fonts/din1451alt_g-webfont.woff2') format('woff2'),
       url('../fonts/din1451alt_g-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'DinBold';
  src: url('../fonts/din1451alt-webfont.woff2') format('woff2'),
       url('../fonts/din1451alt-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;

}



  h1, h2, h3, h4, h5, h6 {
    margin:0;
  }
  body {
    font-size:14px;
    line-height:1.5;
    font-family: 'Roboto Mono', Helvetica, Arial, sans-serif
  }
  .f-xxl {
    font-size:32px;
  }
  .f-xl {
    font-size:24px;
  }
  .f-lg {
    font-size:20px;
  }
  .f-md {
    font-size:16px;
  }
  .f-sm {
    font-size:12px;
  }
  .f-xs {
    font-size:10px;
  }

  h1.large {
    font-size:3.75rem;
    line-height:1.2;
    font-family: 'Din';
  }
  h1 {
    font-size:3;
    line-height:1.25;
    font-family: 'Din';
    letter-spacing:1px;

  }
  h2 {
    font-size:2.25;
    line-height:1.3333333333333333;
    font-family: 'Din';
    letter-spacing:1px;

  }
  h3 {
    font-size:1.5;
    line-height:1.5;
    font-family: 'Din';
    letter-spacing:1px;

  }
  h4 {
    font-size:1rem;
    line-height:1.5;
    font-family: 'Din';
    letter-spacing:1px;
  }
  h5 {
    font-size:0.75rem;
    line-height:1.5;
    font-family: 'Din';
    letter-spacing:1px;
  }
  h6 {
    font-size:0.625rem;
    line-height:1.5;
    font-family: 'Din';
    letter-spacing:1px;
  }

  .highlight {
    color: var(--theme);
  }
  .btn-small {
    padding:0.25rem 0.75rem !important;
    font-size:12px;
  }

  .btn-link, .form-label {
    font-family: 'Din';
    letter-spacing:1px;
    text-transform:uppercase;
    font-weight:500;
  }
  /* Button Styles */
  .btn {
    font-family: 'Din';
    letter-spacing:1px;
    padding: 0.5rem 0.9375rem;
    border-style: solid;
    border-width: 1px;
    border-radius:0 !important;
    text-transform:uppercase;
    transition: background-color 0.24s ease 0s, border-color 0.24s ease 0s, outline-color 0.24s ease 0s, color 0.24s ease 0s;
  }
  .btn-rounded {
    border-radius:21px !important;
  } 
  .btn:focus, .btn:hover, .btn:active {
    box-shadow:0 0 0 0.2rem var(--theme);
  }
  .btn span {
    vertical-align: middle;
  }
  .btn-primary {
    background-color: var(--theme-alt);
    border-color: var(--theme-alt);
    color: var(--light-text);
  }
  .btn-link {
    color: var(--theme);
  }
  .btn-link:focus, .btn-link:hover, .btn-link:active,.btn-link:visited {
    color: var(--theme);

  }
  .btn-primary:focus, .btn-primary:hover, .btn-primary:active,.btn-primary:visited  {
    background-color: var(--theme-alt-overlay) !important;
    border-color: var(--theme-alt) !important;
  }
  .btn:hover,.btn:focus,.btn:active,.btn:visited {
    box-shadow:none;
    border-left:none;
    border-right:none;
    border-top:none;
    border-color: var(--theme);
    border-width: 0.2rem;
    outline:none;
  }

/* Navigation */
.header { padding: 1.25rem 1rem; }
.header img {
  max-height:75px;
}
.navbar {
  margin-top:-1px;
}
.header,.navbar,.navbar-container {
  background-color: var(--theme) !important;
  font-family:'Din';
  letter-spacing:1px;
  font-size:14px;
  line-height:1;
  text-transform:uppercase;
}
.navbar-dark .navbar-nav .nav-link {
  color:var(--light-text);
}
.nav-link-active {
  background:var(--theme-alt);
}
.sub-nav {
  background:var(--theme-alt) !important;
  color:var(--light-text);
  padding:0 1rem !important;
}
.sub-nav .navbar-nav {
  flex-direction: row !important;
}
/* Forms */
.form-control {
  border-radius: 0;
}

/* Input Styles */
/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 1rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: var(--theme-alt);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

a, a:hover,.a:focus,.a:active,.batn:visited {
  color: var(--theme);
}
.nav-tabs .nav-link {
  cursor:pointer;
}
.nav-tabs {
  border-top:1px solid #dee2e6;
  border-bottom: none;
}
.nav-tabs .nav-link.active {
  border-bottom:none;
  border-left:none;
  border-right:none;
  border-top: 1px solid #000000;
  border-radius:0;
}

/* Home Page */
.carousel-item img {
  width: 100%;
}

.nav-item {
  margin-left: 15px;
}

/* Hero Titles */
#hero-title {
  background: linear-gradient(to right, #bdc3c7, #343a40);
  font-size: 2.5rem;
  line-height: 1;
  padding: 30px 10px 40px;
}

/* Equipment Page */
.equipment a {
  color: #8b0000;
}

/* Footer */
#footer {
  background-color: #343a40;
  color: white;
}
.copyright-txt {
  line-height: 1;
}
.masonry-card img {
max-width:100%;
}

.doka--root {
  --doka-effect--invert: 100%;
  --doka-color--alpha: var(--theme-alt);
  --doka-color--alpha-dim: var(--theme-alt-overlay);
}
/* use a hand cursor intead of arrow for the action buttons */
.filepond--file-action-button {
  cursor: pointer;
}

/* the text color of the drop label*/
.filepond--drop-label {
  color: #555;
}

/* underline color for "Browse" button */
.filepond--label-action {
  text-decoration-color: #aaa;
}

/* the background color of the filepond drop area */
.filepond--panel-root {
  background-color: #eee;
}

/* the border radius of the drop area */
.filepond--panel-root {
  border-radius: 0.5em;
}

/* the border radius of the file item */
.filepond--item-panel {
  border-radius: 0.5em;
}

/* the background color of the file and file panel (used when dropping an image) */
.filepond--item-panel {
  background-color: var(--theme-sidebar);
  ;
}

/* the background color of the drop circle */
.filepond--drip-blob {
  background-color: #999;
}

/* the background color of the black action buttons */
.filepond--file-action-button {
  background-color: rgba(0, 0, 0, 0.5);
}

/* the icon color of the black action buttons */
.filepond--file-action-button {
  color: white;
}

/* the color of the focus ring */
.filepond--file-action-button:hover,
.filepond--file-action-button:focus {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.9);
}

/* the text color of the file status and info labels */
.filepond--file {
  color: white;
}

/* error state color */
[data-filepond-item-state*='error'] .filepond--item-panel,
[data-filepond-item-state*='invalid'] .filepond--item-panel {
  background-color: red;
}

[data-filepond-item-state='processing-complete'] .filepond--item-panel {
  background-color: green;
}

/* bordered drop area */
.filepond--panel-root {
  background-color: transparent;
  border: 2px solid #2c3340;
}
/* .filepond--image-preview-overlay-success {
  background:green;
} */
.filepond--image-preview-overlay-success svg {
  display:none;
}
.hero-pic {
  width:100%;
}
.profile-pic {
width: 200px;
    height: 200px;
  
}
.profile-pic img{
        border-radius: 50%;
      border: 4px solid var(--theme-alt);

}
.profile-pic-editor {
  height:512px;
  width:512px;
}
.pic-view {
  position:relative;
  cursor:pointer;
}
.pic-view img {
  object-fit: cover;
  object-position:center; 
  width:100%;
}
.pic-manage {
  visibility:hidden;
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  background:var(--dark-overlay);
  color:#FFF;
  padding:4px;
  border-radius:16px;
  text-align:center;
  cursor:pointer;
}
.pic-editor {
  position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: white;
    width: 100vw;
    height: 100vh;
    max-width:100%;
    max-height:100%;
    z-index:1;
    padding:1rem;
    overflow-y:auto;
}
.pic-editor div:first-child {
  margin:auto;
}
.pic-editor-done {
  z-index:10000;
  position:fixed;
  bottom:1rem;
  right:1rem;
}
.pic-view:hover .pic-manage {
  visibility: visible;
}
#loading {
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background: var(--theme-alt);
  color:var(--light-text);
  z-index:10000;
}
.bl-post {
  position:relative;
}
.bl-post img{
  width:100%;
  height:250px;

  object-fit:cover;
  object-position:bottom;
}
.bl-post-details {
  position:absolute;
  display:none;
  bottom:0;
  left:0;
  right:0;
  background: var(--mid-overlay); 
  color:var(--light-text);  
}
.bl-post:hover .bl-post-details {
  display:flex;
}
.masonry-card .bl-post img {
  height:auto !important;
}
.bl-post .pick-view {
  position:absolute;
  top:0;
  left:0;
  
}
.bl-media {
  height:400px;
  object-fit:cover;
  padding:0.25rem;
  cursor:pointer;
}
.bl-media-featured {
  object-fit:cover;
  padding:0.25rem;
  cursor:pointer;
}
.bl-video {
  height:400px;
  object-fit:cover;
  margin:0.25rem;
}
.pick-view {
  background: var(--theme-alt);
  color: var(--light-text);
  padding:0.25rem 0.5rem 0.25rem 0.25rem;
}
.add-pick {
  cursor:pointer;
}
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  background: var(--theme);
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--theme);
}

/* .masonry-card {
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 100ms ease-in-out;
    width: 100%;
} */

.masonic-container {
  /* min-height: 100vh; */
  width: 100%;
}


.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -8px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 8px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 8px;
}
.featured-collection-container {
  position: relative;
}
.featured-collection {
  width:100%;
    height:400px;
  display:flex;
  overflow-x:scroll;
}

.featured-collection-content {
  min-width: 225px;
  width: 225px;
  margin-right: 0.5rem;
}
.featured-collection-content img {
  object-fit: cover;
  max-width:100%;
  height:calc(100% - 45px);
}
.featured-collection-nav {
  position: absolute;

  top:calc(50% - 25px);
  width:50px;
  height:50px;
  scroll-behavior: smooth;
  background: var(--dark-overlay);
}
.featured-collection-nav-home {
  right:0;
}
.featured-collection-nav-right {
  right:0;
}
.featured-collection-nav svg {
  fill:var(--light-text);
}
.featured-collection-content:nth-of-type(1)
{
  margin-left:calc((100vw - 1140px)/2);
}
.featured-collection::-webkit-scrollbar {
  display: none;
}
.account-page {
  max-width:400px;
}
#main-layout {
  min-height:calc(100vh - 46px);
}
.collection-card {
  width:100%;
}
.collection-card img {
  width:100%;
  object-fit: cover;
}
.story-wrapper {
  position:relative;
}
.story-marker-left {
  position: absolute;
  border: 3px solid var(--theme-alt);
  background:var(--light-text);  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: 50%;
  left: 0;
  margin-top: -8px;
  margin-left: -9px;
}
.story-marker-right {
  position: absolute;
  border: 3px solid var(--theme-alt);
  background:var(--light-text);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: 50%;
  right: 0;
  /* margin: 10px 0 -10px -10px; */
  margin-top: -8px;
  margin-right: -9px;
}
.story-left{
  border-right:3px solid var(--theme-alt);
}
.story-right{
  border-left:3px solid var(--theme-alt);
}
.story-card {
  background: var(--bg-grey);
}
.story-card .carousel-item {
  height:300px;
}
.story-card .carousel-item img {
  width:100%;
  height:100%;
  object-fit:cover;
}
.story-mark-mobile {
  width:50%;
  border-right:3px solid var(--theme-alt);
}
@media(max-width:1199px) {

  .featured-collection-content:nth-of-type(1)
  {
    margin-left:calc((100vw - 960px)/2);
  }

}
@media(max-width:991px) {
  .featured-collection-content:nth-of-type(1)
  {
    margin-left:calc((100vw - 720px)/2);
  }
  .featured-collection-nav {
    display:none;
  }
}
@media(max-width:767px) {
  .profile-pic {
      width: 75px;
          height: 75px;
  }
  .profile-pic img{
  border: 2px solid var(--theme-alt);
}
.featured-collection-content:nth-of-type(1)
{
  margin-left:calc((100vw - 550px)/2);
}
}
@media(max-width:575px) {
  .nav-item {
    margin-left:0;
}
  .featured-collection {
      height:275px;
  }
  
  .featured-collection-content {
    min-width: 200px;
    width: 200px;
  }
  .featured-collection-content:nth-of-type(1)
  {
    margin-left:0;
  }
}