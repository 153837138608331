/* layout styles */
nav {
  background: var(--theme);
}
nav .brand {
  font-size: 1.5rem;
  margin-left: 1.5rem;
}
.navbar-brand span {
  font-family: "BoldFont";
}

/* form styles */
form .input-field {
  margin: 2rem 0;
}
.sidebar {
  background: var(--theme-sidebar);
  min-height: 100vh;
  box-shadow:inset -1px 0 0 rgba(0, 0, 0, .1)
}
.sidebar,
#main {
  padding-top: 80px;
}
#main .container:first-child {
  margin-left: 0 !important;
}
nav i {
  cursor: pointer;
}
a.nav-link,a.nav-link:hover,a.nav-link:focus,a.nav-link:active,a.nav-link:visited {
  color:var(--dark-text);
  text-decoration:none;
}
@media (max-width: 1199px) {
  .sidebar {
    position: absolute !important;
    left: 0;
    z-index: 1;
  }

  .sidebar-titles-hide {
    display: none !important;
  }
  .sidebar-titles-show {
    min-width: 160px;
  }
  #main {
    padding-left: 64px !important;
  }
}
@media (max-width: 767px) {
  .sidebar-hide {
    display: none !important;
  }

  .nav-link {
    padding: 0.5rem 0.5rem !important;
  }
  #main {
    padding-left: 24px !important;
    padding-top: 64px;
  }
}
